.select-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.options {
  position: absolute;
  display: flex;
  flex-direction: column;

  max-height: 180px;

  border-width: 2px;
  border-style: none solid solid solid;
  border-color: transparent;
  background-color: $dirty-white;
  border-radius: 0 0 20px 20px;
  margin-top: -2px;

  transition: opacity 500ms ease;
  box-sizing: border-box;
  overflow: auto;
  z-index: $z-select;

  &.relative {
    position: relative;
  }

  &.small {
    font-size: 12px;
  }

  &.medium {
    font-size: 13px;
    border-radius: 0 0 4px 4px;
  }

  &.large {
    font-size: 14px;
    border-radius: 0 0 4px 4px;
  }

  &.outlined {
    border-color: $light-gray;
    background-color: $white;
  }

  &.outlined,
  &.contained {
    @each $name, $pairs in $color-pairs {
      &.#{$name} {
        border-color: map-get($pairs, "light");
      }
    }
  }

  .option {
    font: 13px inter, sans-serif;
    letter-spacing: 0.2px;
    line-height: 18px;
    color: $black;
    text-align: center;

    padding: 8px;

    cursor: pointer;
    transition: background-color 100ms ease;

    &.selected,
    &:hover {
      background-color: $dark-white;
    }
  }
}

.select {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 16px;
  border: 0;
  border-radius: 20px;

  @include inter(400);
  font-size: 13px;
  letter-spacing: 0.2px;
  line-height: 18px;

  box-sizing: border-box;
  border: 1px solid transparent;
  background-color: transparent;
  color: $black;

  width: 100%;
  height: fit-content;

  cursor: pointer;

  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-property: background-color, border-color;

  @media screen and (max-width: $md) {
    padding: 0 12px;
  }

  .chevron {
    position: absolute;
    right: 8px;

    @media screen and (max-width: $md) {
      right: 4px;
    }
  }

  &.open {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-color: transparent !important;
  }

  &:focus {
    background-color: $dirty-white;
    border-width: 2px;
    outline: none;
  }

  &.disabled {
    @include disabled;
    @include inter(700);
  }

  &.small {
    font-size: 12px;
    padding: 6px 14px;
    &:focus {
      padding: 5px 13px;
    }
  }

  &.medium {
    font-size: 13px;
    border-radius: 4px;
    padding: 8px 14px;
    &:focus {
      padding: 7px 13px;
    }
  }

  &.large {
    font-size: 14px;
    border-radius: 4px;
    padding: 16px;
    &:focus {
      padding: 15px;
    }
  }

  &.contained {
    background-color: $white;

    &:hover {
      border-color: $black;
    }

    @each $name, $pairs in $color-pairs {
      &.#{$name} {
        &:focus {
          border-color: map-get($pairs, "light");
        }
      }
    }

    &.white,
    &.light-gray {
      &:focus {
        border-color: $light-gray;
      }
    }
  }

  &.outlined {
    border-color: $light-gray;
    &:hover {
      border-color: $black;
    }

    @each $name, $pairs in $color-pairs {
      &.#{$name} {
        &:focus {
          border-color: map-get($pairs, "light");
        }
      }
    }

    &.white,
    &.light-gray {
      &:focus {
        border-color: $dark-gray;
      }
    }
  }
}

.entry.error {
  .options,
  .select {
    border-color: $red;
    border-color: $red;
  }
}
