.pill {
  border: 0;

  font-size: 16px;
  @include inter(700);
  line-height: 18px;
  white-space: nowrap;
  letter-spacing: 0.5px;

  box-sizing: border-box;
  border: 1px solid transparent;

  &.extra-small {
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 2px;
  }

  &.small {
    font-size: 12px;
    padding: 6px 10px;
    border-radius: 12px;
  }

  &.medium {
    font-size: 14px;
    padding: 12px 18px;
    border-radius: 10px;
  }

  &.large {
    padding: 19px 38.83px;
    border-radius: 12px;
  }

  &.contained {
    color: $white;

    @each $name, $pairs in $color-pairs {
      &.#{$name} {
        background-color: map-get($pairs, "light");
        border-color: map-get($pairs, "light");
      }
    }
  }

  &.outlined {
    color: $light-black;

    @each $name, $pairs in $color-pairs {
      &.#{$name} {
        border-color: map-get($pairs, "light");
      }
    }
  }
}
