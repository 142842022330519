.page-cover {
  position: fixed;

  width: 100vw;
  height: 100vh;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: $z-page-cover;

  background-color: rgba($black, 0.8);

  &.transparent {
    background-color: transparent;
  }
}
