.snackbars {
  position: fixed;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 8px;
  z-index: $z-snackbar;
  min-width: 200px;
  max-width: 400px;
  margin: 8px;

  @media (max-width: $sm) {
    max-width: unset;
    min-width: unset;
    width: calc(100vw - 16px);
  }

  &.top {
    top: 0;
  }
  &.bottom {
    bottom: 0;
  }

  &.left {
    left: 0;
    .snackbar {
      transform: translateX(calc(-100% - 12px));
    }
  }
  &.right {
    right: 0;
    .snackbar {
      transform: translateX(calc(100% + 12px));
    }
  }

  .snackbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    width: calc(100% - 24px);
    min-height: 40px;

    padding: 4px 12px;

    background-color: $dirty-white;
    box-shadow: 0 0 5px $gray;
    border-radius: 12px;

    transition: transform 300ms ease;

    &.appear-active,
    &.appear-active,
    &.enter-active,
    &.enter-done {
      transform: translate(0);
    }

    .icon {
      font-size: 32px;
      min-width: 32px;

      @media (max-width: $sm) {
        font-size: 24px;
        min-width: 24px;
      }
    }

    p {
      color: inherit;
      @include inter(700);
      text-align: center;

      @media (max-width: $sm) {
        font-size: 13px;
      }
    }

    .close {
      font-size: 24px;
      min-width: 24px;
    }

    &.info {
      background-color: $light-blue;
      color: $white;
    }

    &.error {
      background-color: $red;
      color: $white;
    }

    &.warn {
      background-color: $orange;
      color: $white;
    }

    &.success {
      background-color: $green;
      color: $white;
    }
  }
}
