.text-bold {
  @include inter(700);
}
.text-italic {
  font-style: italic;
}

@each $name, $color in $colors {
  .text-#{$name} {
    color: $color !important;
  }
}

@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: $color !important;
  }
}

@each $name, $color in $colors {
  .fill-#{$name} {
    fill: $color !important;
  }
}
