@import "./fonts/fonts.scss";
@import "./vars.scss";
@import "./mixins.scss";

@import "./common/pill.scss";
@import "./common/divider.scss";
@import "./common/progress.scss";
@import "./common/snackbars.scss";
@import "./common/modal.scss";
@import "./common/pageCover.scss";

@import "./forms/button.scss";
@import "./forms/input.scss";
@import "./forms/checkbox.scss";
@import "./forms/select.scss";
@import "./forms/datePicker.scss";
@import "./forms/phoneInput.scss";
@import "./forms/form.scss";

@import "./home/home.scss";

@import "./generic.scss";
@import "./misc.scss";

* {
  scrollbar-width: thin;
  scrollbar-color: $purple $light-purple;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px $purple;
  -webkit-box-shadow: inset 0 0 6px $purple;
}

*::-webkit-scrollbar-thumb {
  background-color: $purple;
}

#content-root {
  position: relative;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
small,
label,
li {
  margin: 0;
  padding: 0;
  letter-spacing: 0.5px;
  line-height: 1.3em;
  @include inter(400);
}

small {
  font-size: 12px;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

pre {
  white-space: pre-wrap;
}

a {
  text-decoration: none;
  color: $purple;
  @include inter(700);
  cursor: pointer;

  &.disabled {
    @include disabled;
  }
}

.disable-links {
  a {
    @include disabled;
  }
}

ul,
ol {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.scroll-to-top {
  position: fixed;
  right: 16px;
  bottom: 32px;

  transition: all 300ms ease;
  z-index: $z-snackbar;

  &.hidden {
    bottom: 0;
    transform: translateY(100%);

    @media (max-width: $sm) {
      transform: translate(50%, 100%);
    }
  }
}
