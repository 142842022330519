.divider {
  &.horizontal {
    width: 100%;
    height: 1px;
    min-height: 1px;
    max-height: 1px;
  }

  &.vertical {
    height: 100%;
    width: 1px;
    min-width: 1px;
    max-width: 1px;
  }
}
