.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  &.textarea {
    height: unset !important;
  }
}

input,
textarea {
  &.input {
    border-radius: 20px;

    font-size: 13px;
    letter-spacing: 0.2px;
    line-height: 1.5em;

    box-sizing: border-box;
    border: 1px solid transparent;
    background-color: $dark-white;
    color: $black;
    @include inter(500);

    width: 100%;
    height: 100%;

    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-property: background-color, border-color;

    &.multiline {
      resize: none;
      min-height: 100px;
    }

    &::placeholder {
      color: $light-gray;
    }

    &:focus {
      background-color: rgba($dark-white, 0.25);
      border-width: 2px;
      outline: none;
      @-moz-document url-prefix() {
        border-width: 3px;
      }
    }

    &.disabled {
      @include disabled;
      @include inter(700);
    }

    &.small {
      font-size: 12px;
      padding: 6px 14px;
      &:focus {
        padding: 5px 13px;
      }
    }

    &.medium {
      font-size: 13px;
      border-radius: 4px;
      padding: 8px 14px;
      &:focus {
        padding: 7px 13px;
      }
    }

    &.large {
      font-size: 14px;
      border-radius: 4px;
      padding: 16px;
      &:focus {
        padding: 15px;
      }
    }

    &.contained {
      &:hover {
        border-color: $black;
      }

      @each $name, $pairs in $color-pairs {
        &.#{$name} {
          &:focus {
            border-color: map-get($pairs, "light");
          }
        }
      }

      &.white,
      &.light-gray {
        &:focus {
          border-color: $dark-white;
        }
      }

      &.success {
        border-color: $green;
      }
    }

    &.outlined {
      border-color: $light-gray;
      background-color: $white;
      &:hover {
        border-color: $black;
      }

      @each $name, $pairs in $color-pairs {
        &.#{$name} {
          &:focus {
            border-color: map-get($pairs, "light");
          }
        }
      }

      &.white,
      &.light-gray {
        &:focus {
          border-color: $dark-white;
        }
      }

      &.success {
        border-color: $green;
      }
    }
  }
}

button,
svg {
  &.input-icon {
    position: absolute;
    z-index: 1000;
    padding: 8px;

    @each $name, $color in $colors {
      &.#{$name} {
        color: $color;
      }
    }
  }
}

.entry.error {
  input,
  textarea {
    &.input {
      border-color: $red;
    }
  }
}
