.overlay {
  position: fixed;
  inset: 0;
  height: 100vh;
  width: 100vw;
  z-index: $z-overlay;
  background-color: rgba($black, 0.5);
}

.logo {
  display: block;
  padding: 32px;
  @media (max-width: $sm) {
    padding: 8px 32px;
  }
}

.tooltip-wrapper {
  width: 100%;
  &.multiline {
    height: 100%;
  }
}

.tooltip-base {
  position: absolute;
  box-sizing: border-box;
  transition: opacity 500ms ease;
  z-index: $z-tooltip;
  background-color: $white;
  min-width: 120px;
  padding: 12px 24px 12px 12px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba($black, 0.1);

  @media (max-width: $md) {
    padding: 4px 20px 4px 8px;
  }

  .close {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    margin: 6px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: $black;
    cursor: pointer;

    @media (max-width: $md) {
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }

    .inner {
      transition: font-weight 150ms ease;
      font-size: 10px;
      &:hover {
        @include inter(700);
      }
    }
  }

  &.top {
    transform: translate(-50%, calc(-100% - 10px));
    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;

      inset: auto 0 0 0;
      margin: auto;
      transform: translateY(100%);

      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $white;
    }
  }

  &.bottom {
    transform: translate(-50%, 10px);

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;

      inset: 0 0 auto 0;
      margin: auto;
      transform: translateY(-100%);

      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $white;
    }
  }

  &.right {
    transform: translate(10px, -50%);

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;

      inset: 0 auto 0 0;
      margin: auto;
      transform: translateX(-100%);

      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid $white;
    }
  }

  &.left {
    transform: translate(calc(-100% - 10px), -50%);

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;

      inset: 0 0 0 auto;
      margin: auto;
      transform: translateX(100%);

      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid $white;
    }
  }
}

@keyframes top-progress {
  0% {
    width: 0%;
    left: 0;
  }
  25% {
    width: 50%;
    left: 0%;
  }
  50% {
    width: 25%;
    left: 37.5%;
  }
  75% {
    width: 50%;
    left: 50%;
  }
  100% {
    width: 0%;
    left: 100%;
  }
}

.top-progress {
  position: fixed;

  inset: 0 0 none 0;
  height: 4px;
  width: 100%;
  z-index: $z-root;
  background-color: rgba($dark-white, 0.5);
  box-shadow: 0 1px 2px rgba($gray, 0.75);

  .inner {
    position: absolute;
    height: 100%;
    width: 100px;
    background-color: $dark-purple;
    animation: top-progress 2000ms infinite linear;
  }
}

.request-error {
  margin: 16px;

  text-align: center;

  .title {
    font-size: 24px;
    color: $light-black;
    @include inter(700);

    @media (max-width: $sm) {
      font-size: 18px;
    }
  }

  .small-text {
    margin-top: 12px;

    font-size: 12px;
    color: $dark-gray;
    @include inter(700);

    @media (max-width: $sm) {
      font-size: 8px;
    }
  }

  .text {
    margin-top: 16px;

    font-size: 16px;
    color: $red;

    @media (max-width: $sm) {
      font-size: 12px;
    }
  }
}

.circular-blur {
  width: 800px;

  @media (min-width: $sl) {
    width: 1200px;
  }

  @media (min-width: $ul) {
    width: 1800px;
  }

  @media (max-width: $lg) {
    width: 750px;
  }

  @media (max-width: $md) {
    width: 600px;
  }

  @media (max-width: $sm) {
    width: 450px;
  }
}

.intersect-animation {
  &:not(.enter-done) {
    &.slide-right {
      transform: translateX(-100vw) !important;
    }

    &.slide-left {
      transform: translateX(100vw) !important;
    }

    &.slide-down {
      transform: translateY(-100vw) !important;
    }

    &.slide-right,
    &.slide-left {
      &.enter-active {
        transform: translate(0) !important;
      }
    }
  }

  &.fade {
    &:not(.enter-done) {
      opacity: 0;
    }

    &.enter-active {
      opacity: 1 !important;
    }
  }
}
