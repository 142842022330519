.form-wrapper {
  position: relative;

  .form {
    display: flex;
    flex-direction: column;
    transition: opacity 300ms linear, transform 300ms linear;
    width: 100%;

    &.no-transition {
      transition: none !important;
    }

    &.next {
      position: absolute;
      inset: 0;
    }

    .inner {
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      flex-grow: 1;
      overflow-y: auto;
      gap: 40px 32px;
      padding: 40px 4px;

      @media (max-width: $lg) {
        gap: 40px 32px;
        padding: 32px 4px;
      }

      @media (max-width: $md) {
        gap: 32px 32px;
        margin: 16px 4px;
      }

      @media (max-width: $sm) {
        gap: 24px 32px;
        margin: 8px 4px;
        padding: 8px 4px;
      }

      .row {
        display: flex;
        box-sizing: border-box;
        justify-content: center;
        gap: 24px;

        .label {
          width: 100%;
          padding: 8px 2px;
          letter-spacing: 0.1em;
          font-size: 14px;
          @include inter(800);
        }

        .entry {
          flex-grow: 1;
          display: flex;
          flex-wrap: wrap;
          box-sizing: border-box;
          height: 100%;

          .textarea {
            width: 100%;
            height: 100%;
          }

          .input,
          .select {
            width: 100%;
          }

          .input-wrapper,
          .select-wrapper {
            width: 100%;
          }
        }
      }
    }
  }

  .form-error {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    margin-top: 16px;

    @include inter(700);
    font-size: 14px;
    color: $red;
    text-align: center;

    svg {
      font-size: 24px;
    }
  }
}

.entry {
  position: relative;
  &.validation {
    display: flex;
    align-items: center;
    margin: 4px;
    gap: 8px;

    &.not-tooltip {
      position: absolute;
      align-items: flex-start;
      top: 100%;
      width: 100%;
    }

    @media (max-width: $md) {
      margin: 8px;
    }

    &.error {
      color: $red;
    }
    &.success {
      color: $green;
    }

    .text {
      font-size: 13px;
      @include inter(700);

      @media (max-width: $md) {
        font-size: 12px;
        margin: 0;
      }
    }
  }
}
