$white: rgba(255, 255, 255, 1);
$dirty-white: rgba(252, 252, 252, 1);
$stained-white: rgba(247, 247, 247, 1);
$dark-white: rgba(242, 242, 242, 1);

$extra-light-gray: rgba(224, 224, 224, 1);
$light-gray: rgba(175, 175, 175, 1);
$pale-gray: rgb(151, 151, 151);
$steel-gray: rgba(143, 146, 161, 1);
$gray: rgba(127, 127, 127, 1);
$dark-gray: rgba(80, 80, 80, 1);

$light-black: rgba(30, 31, 32, 1);
$black: rgba(0, 1, 2, 1);

$light-purple: rgba(186, 150, 255, 1);
$purple: rgba(130, 66, 253, 1);
$dark-purple: rgba(100, 36, 223, 1);

$yellow: rgba(254, 183, 47, 1);
$dark-yellow: rgba(224, 153, 17, 1);

$light-blue: rgba(73, 190, 253, 1);
$blue: rgba(43, 160, 223, 1);

$discord-blue: rgba(88, 101, 242, 1);
$dark-discord-blue: rgba(58, 71, 212, 1);

$twitter-blue: rgb(73, 190, 253);
$dark-twitter-blue: rgba(46, 146, 223);

$youtube-red: rgb(255, 0, 32, 1);
$dark-youtube-red: rgba(225, 0, 02, 1);

$tiktok-black: rgb(28, 28, 31);
$dark-tiktok-black: rgb(18, 18, 21);

$facebook-blue: rgba(66, 103, 178, 1);
$dark-facebook-blue: rgb(36, 73, 148, 1);

$instagram-orange: rgba(253, 89, 73, 1);
$dark-instagram-orange: rgba(223, 59, 43, 1);
$instagram-rainbow: radial-gradient(
  circle at 30% 107%,
  rgba(253, 244, 151, 1) 0%,
  rgba(253, 244, 151, 1) 5%,
  rgba(253, 89, 73, 1) 45%,
  rgba(214, 36, 159, 1) 60%,
  rgba(40, 90, 235, 1) 90%
);
$dark-instagram-rainbow: radial-gradient(
  circle at 30% 107%,
  rgba(223, 214, 121, 1) 0%,
  rgba(223, 214, 121, 1) 5%,
  rgba(223, 59, 43, 1) 45%,
  rgba(184, 6, 129, 1) 60%,
  rgba(10, 60, 206, 1) 90%
);

$google-red: rgba(219, 68, 55, 1);
$dark-google-red: rgba(189, 38, 25, 1);

$orange: rgba(254, 146, 51, 1);
$dark-orange: rgba(224, 116, 21, 1);

$red: rgba(255, 67, 67, 1);
$dark-red: rgba(225, 47, 47, 1);

$green: rgba(30, 221, 118, 1);
$dark-green: rgba(10, 201, 98, 1);

$colors: (
  "white": $white,
  "dirty-white": $dirty-white,
  "stained-white": $stained-white,
  "dark-white": $dirty-white,
  "extra-light-gray": $extra-light-gray,
  "light-gray": $light-gray,
  "gray": $gray,
  "dark-gray": $dark-gray,
  "light-black": $light-black,
  "black": $black,
  "light-purple": $light-purple,
  "purple": $purple,
  "dark-purple": $dark-purple,
  "yellow": $yellow,
  "dark-yellow": $dark-yellow,
  "light-blue": $light-blue,
  "blue": $blue,
  "discord-blue": $discord-blue,
  "dark-discord-blue": $dark-discord-blue,
  "twitter-blue": $twitter-blue,
  "dark-twitter-blue": $dark-twitter-blue,
  "youtube-red": $youtube-red,
  "dark-youtube-red": $dark-youtube-red,
  "tiktok-black": $tiktok-black,
  "dark-tiktok-black": $dark-tiktok-black,
  "facebook-blue": $facebook-blue,
  "dark-facebook-blue": $dark-facebook-blue,
  "instagram-orange": $instagram-orange,
  "dark-instagram-orange": $dark-instagram-orange,
  "google-red": $google-red,
  "dark-google-red": $dark-google-red,
  "orange": $orange,
  "dark-orange": $dark-orange,
  "red": $red,
  "dark-red": $dark-red,
  "green": $green,
  "dark-green": $dark-green,
);

$color-pairs: (
  "white": (
    "light": $white,
    "dark": $dark-white,
  ),
  "dark-white": (
    "light": $dark-white,
    "dark": $extra-light-gray,
  ),
  "extra-light-gray": (
    "light": $extra-light-gray,
    "dark": $light-gray,
  ),
  "light-gray": (
    "light": $light-gray,
    "dark": $gray,
  ),
  "gray": (
    "light": $gray,
    "dark": $dark-gray,
  ),
  "dark-gray": (
    "light": $dark-gray,
    "dark": $light-black,
  ),
  "light-black": (
    "light": $light-black,
    "dark": $black,
  ),
  "black": (
    "light": $black,
    "dark": $light-black,
  ),
  "purple": (
    "light": $purple,
    "dark": $dark-purple,
  ),
  "orange": (
    "light": $orange,
    "dark": $dark-orange,
  ),
  "yellow": (
    "light": $yellow,
    "dark": $dark-yellow,
  ),
  "light-blue": (
    "light": $light-blue,
    "dark": $blue,
  ),
  "discord-blue": (
    "light": $discord-blue,
    "dark": $dark-discord-blue,
  ),
  "twitter-blue": (
    "light": $twitter-blue,
    "dark": $dark-twitter-blue,
  ),
  "youtube-red": (
    "light": $youtube-red,
    "dark": $dark-youtube-red,
  ),
  "tiktok-black": (
    "light": $tiktok-black,
    "dark": $dark-tiktok-black,
  ),
  "facebook-blue": (
    "light": $facebook-blue,
    "dark": $dark-facebook-blue,
  ),
  "instagram-orange": (
    "light": $instagram-orange,
    "dark": $dark-instagram-orange,
  ),
  "google-red": (
    "light": $google-red,
    "dark": $dark-google-red,
  ),
  "red": (
    "light": $red,
    "dark": $dark-red,
  ),
  "error": (
    "light": $red,
    "dark": $dark-red,
  ),
  "green": (
    "light": $green,
    "dark": $dark-green,
  ),
  "success": (
    "light": $green,
    "dark": $dark-green,
  ),
);

$drop-shadow: rgba(150, 150, 150, 1);

$xs: 0px;
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1780px;
$sl: 2560px;
$ul: 3840px;

$z-root: 16000;
$z-tooltip: 15000;
$z-select: 14000;
$z-modal: 13000;
$z-page-cover: 12000;
$z-snackbar: 11000;
$z-drawer: 10000;
$z-overlay: 9000;
$z-header: 8000;

$z-page: 5000;

$z-back: -10000;
