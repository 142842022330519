.modal {
  position: fixed;

  width: fit-content;
  height: fit-content;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;

  background-color: $white;
  border-radius: 5px;

  z-index: $z-modal;
  overflow: hidden;
  box-sizing: border-box;

  @media (max-width: $sm) {
    margin: 8px;
  }

  .modal-close-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 8px;
  }

  .modal-content {
    max-width: 70vw;
    max-height: 70vh;
    overflow: auto;
    padding: 8px;

    @media (max-width: $sm) {
      max-width: calc(100vw - 16px);
      max-height: calc(100vh - 16px);
    }

    .form .inner {
      padding: 0 16px 40px 0;
      gap: 24px 0;
    }
  }
}
