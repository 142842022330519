@mixin interFontFace($name, $weight, $style) {
  font-family: Inter;
  src: url("../../media/fonts/inter/#{$name}");
  font-weight: $weight;
  font-style: $style;
}

@font-face {
  @include interFontFace("Inter-Thin.ttf", 100, normal);
}

@font-face {
  @include interFontFace("Inter-ExtraLight.ttf", 200, normal);
}

@font-face {
  @include interFontFace("Inter-Light.ttf", 300, normal);
}

@font-face {
  @include interFontFace("Inter-Regular.ttf", 400, normal);
}

@font-face {
  @include interFontFace("Inter-Medium.ttf", 500, normal);
}

@font-face {
  @include interFontFace("Inter-SemiBold.ttf", 600, normal);
}

@font-face {
  @include interFontFace("Inter-Bold.ttf", 700, normal);
}

@font-face {
  @include interFontFace("Inter-ExtraBold.ttf", 800, normal);
}

@font-face {
  @include interFontFace("Inter-Black.ttf", 900, normal);
}
