#home-page {
  position: relative;
  background-color: $black;

  min-height: 100vh;

  overflow: hidden;

  .square {
    transition: transform 500ms ease;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 32px 128px;
    width: calc(100% - 256px);

    z-index: $z-header;
    transition: all 300ms ease;

    @media (min-width: $sl) {
      padding: 48px 192px;
      width: calc(100% - 384px);
    }

    @media (min-width: $ul) {
      padding: 64px 256px;
      width: calc(100% - 512px);
    }

    @media (max-width: $xl) {
      padding: 28px 56px;
      width: calc(100% - 112px);
    }

    @media (max-width: $lg) {
      padding: 28px 56px;
      width: calc(100% - 112px);
    }

    @media (max-width: $md) {
      padding: 24px 48px;
      width: calc(100% - 96px);
    }

    @media (max-width: $sm) {
      padding: 24px 48px;
      width: calc(100% - 96px);
      justify-content: flex-end;
    }

    .banner {
      width: auto;
      height: 50px;

      z-index: $z-drawer;

      @media (min-width: $sl) {
        height: 60px;
      }

      @media (min-width: $ul) {
        height: 100px;
      }

      @media (max-width: $md) {
        height: 45px;
      }

      @media (max-width: $sm) {
        height: 30px;
      }
    }

    .circular-blur {
      position: absolute;
      right: 25vw;

      transform: translate(45%, -70%);
    }

    .button {
      width: 180px;
      border-radius: 50px;

      @media (max-width: $sm) {
        width: unset;
      }

      z-index: 100;
    }
  }

  .top-banner {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0 0 64px 256px;

    z-index: 100;

    .circular-blur {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-80%, -10%);
    }

    @media (max-width: $lg) {
      flex-direction: column;
      align-items: flex-end;
      gap: 128px;
      padding: 0 0 64px 0;
    }

    @media (max-width: $md) {
      padding: 64px 0;
    }

    @media (max-width: $sm) {
      gap: 64px;
    }

    .left {
      position: relative;
      width: fit-content;
      height: fit-content;

      z-index: 100;

      padding-top: 32px;

      @media (max-width: $lg) {
        align-self: center;
      }

      .square {
        position: absolute;

        &:nth-of-type(1) {
          height: auto;

          top: 50px;
          right: 0;

          transform: rotate(15deg);
          @include float(3000ms, 25px);

          @media (min-width: $sl) {
            top: 75px;
            width: 60px;
          }

          @media (min-width: $ul) {
            top: 150px;
            width: 100px;
          }

          @media (max-width: $lg) {
            top: 175px;
            right: -80px;
          }

          @media (max-width: $md) {
            width: 40px;
            top: 160px;
            right: 0;
          }

          @media (max-width: $sm) {
            width: 30px;
            top: -110px;
            right: 130px;
          }
        }

        &:nth-of-type(2) {
          height: auto;
          width: 34px;

          top: -60px;
          right: 100px;

          transform: rotate(-45deg);
          @include float(3000ms, 20px);
          animation-delay: 150ms;

          @media (min-width: $sl) {
            width: 40px;
          }

          @media (min-width: $ul) {
            width: 60px;
          }

          @media (max-width: $lg) {
            top: 60px;
            right: 0;
          }

          @media (max-width: $md) {
            top: 40px;
            right: -30px;
          }

          @media (max-width: $sm) {
            width: 30px;

            right: 220px;
            top: -60px;
          }
        }

        &:nth-of-type(3) {
          height: auto;

          top: calc(100% - 25px);
          right: 125px;

          transform: rotate(-15deg);
          @include float(3000ms, 25px);
          animation-delay: 1000ms;

          @media (min-width: $sl) {
            width: 60px;
          }

          @media (min-width: $ul) {
            width: 100px;
            right: 175px;
          }

          @media (max-width: $md) {
            display: none;
          }
        }
      }

      .title {
        position: relative;

        font-size: 140px;
        @include inter(600);
        color: $white;
        line-height: 1em;

        transition: font-size 300ms ease;

        @media (min-width: $sl) {
          font-size: 200px;
        }

        @media (min-width: $ul) {
          font-size: 300px;
        }

        @media (max-width: $md) {
          font-size: 100px;
        }

        @media (max-width: $sm) {
          font-size: 48px;
        }

        z-index: 100;

        .game-wrapper {
          position: relative;
          display: flex;

          .speech-bubble-wrapper {
            position: relative;
            .speech-bubble {
              position: absolute;
              left: 32px;
              bottom: 0;
              width: 160px;

              transition: all 300ms ease;

              @media (min-width: $sl) {
                width: 200px;
              }

              @media (min-width: $ul) {
                width: 300px;
              }

              @media (max-width: $sm) {
                width: 100px;
              }

              &:hover {
                transform: scale(1.05);
              }
            }
          }
        }
      }

      .sub-title {
        position: relative;

        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 24px;

        margin-top: 24px;

        font-size: 24px;
        @include inter(300);
        color: $white;

        transition: font-size 300ms ease;

        @media (min-width: $sl) {
          font-size: 32px;
        }

        @media (min-width: $ul) {
          font-size: 40px;
        }

        @media (max-width: $md) {
          font-size: 18px;
        }

        @media (max-width: $sm) {
          font-size: 12px;
          flex-direction: column;
          align-items: flex-start;
        }

        z-index: 100;

        .button {
          border-radius: 50px;
          white-space: nowrap;

          @media (min-width: $sl) {
            padding: 32px 64px;
            font-size: 22px;
          }

          @media (min-width: $ul) {
            padding: 48px 96px;
            font-size: 34px;
            border-radius: 100px;
          }

          &:hover {
            background-color: $purple;
            color: $white;
          }

          &:active {
            background-color: $dark-purple;
          }
        }

        .microphone-wrapper {
          position: relative;

          @media (max-width: $sm) {
            width: 100%;
          }

          .microphone {
            position: absolute;
            right: -230px;
            top: -80px;
            width: 50px;

            @media (min-width: $sl) {
              width: 60px;
              right: -340px;
            }

            @media (min-width: $ul) {
              width: 100px;
              top: -110px;
              right: -650px;
            }

            @media (max-width: $md) {
              right: -45px;
              top: -40px;
            }

            @media (max-width: $sm) {
              right: 0;
              top: -120px;
            }

            transition: transform 300ms ease;

            &:hover {
              transform: rotate(5deg);
            }
          }
        }
      }
    }

    .right {
      position: relative;

      .panel-wrapper {
        position: relative;
        height: 100%;
        right: -128px;

        @media (max-width: $lg) {
          width: 100%;
        }

        @media (max-width: $md) {
          width: 100%;
          right: -64px;
        }

        @media (max-width: $sm) {
          right: -64px;
        }

        transition: all 300ms ease;

        .panel {
          position: relative;

          height: 600px;

          border-radius: 20px;

          @media (min-width: $sl) {
            height: 800px;
          }

          @media (min-width: $ul) {
            height: 1200px;
          }

          @media (max-width: $lg) {
            height: auto;
            width: 100%;
          }

          @media (max-width: $sm) {
            width: 150%;
          }

          transition: all 300ms ease;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    margin: 0 224px 64px 224px;

    @media (min-width: $sl) {
      margin: 0 320px 128px 320px;
    }

    @media (min-width: $ul) {
      margin: 0 448px 256px 448px;
    }

    @media (max-width: $xl) {
      margin: 0 128px 64px 128px;
    }

    @media (max-width: $lg) {
      margin: 0 96px 64px 96px;
    }

    @media (max-width: $md) {
      margin: 0 64px 64px 64px;
    }

    @media (max-width: $sm) {
      margin: 0 32px 96px 32px;
    }

    transition: margin 300ms ease;

    .top-text-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 24px;

      @media (max-width: $sm) {
        text-align: center;
      }

      .square {
        position: absolute;

        transition: all 300ms ease;

        &:nth-of-type(1) {
          top: 75px;
          right: 350px;

          transform: rotate(60deg);
          @include float(3000ms, 25px);

          @media (max-width: $xl) {
            top: -25px;
            right: 300px;
          }

          @media (max-width: $sm) {
            top: -175px;
            right: 25px;
          }
        }

        &:nth-of-type(2) {
          top: 200px;
          right: 500px;
          width: 40px;

          transform: rotate(25deg);
          @include float(3000ms, 20px);
          animation-delay: 150ms;

          @media (max-width: $xl) {
            top: calc(100% + 30px);
            left: -25px;
          }

          @media (max-width: $sm) {
            top: -100px;
            right: 125px;
          }
        }

        &:nth-of-type(3) {
          top: calc(100% - 50px);
          bottom: -125px;
          left: 50px;
          width: 50px;

          transform: rotate(70deg);
          @include float(3000ms, 20px);
          animation-delay: 300ms;

          @media (max-width: $sm) {
            top: calc(100% + 40px);
            left: 25px;
          }
        }
      }

      .circular-blur {
        position: absolute;
        right: 0;
        transform: translate(80%, -30%);
      }

      .top-text {
        display: flex;
        flex-direction: column;
        gap: 32px;

        width: 55%;

        color: $white;
        font-size: 44px;

        transition: font-size 300ms ease;

        @media (min-width: $sl) {
          font-size: 64px;
        }

        @media (min-width: $ul) {
          font-size: 96px;
        }

        @media (max-width: $xl) {
          width: 100%;
        }

        @media (max-width: $lg) {
          font-size: 40px;
        }

        @media (max-width: $md) {
          font-size: 38px;
        }

        @media (max-width: $sm) {
          font-size: 18px;
        }

        &.title {
          font-size: 48px;

          @media (min-width: $sl) {
            font-size: 64px;
          }

          @media (min-width: $ul) {
            font-size: 96px;
          }

          @media (max-width: $lg) {
            font-size: 44px;
          }

          @media (max-width: $md) {
            font-size: 42px;
          }

          @media (max-width: $sm) {
            font-size: 24px;
          }
        }
      }
    }

    .bars {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 112px;

      margin-top: 96px;

      @media (min-width: $sl) {
        gap: 256px;
      }

      @media (min-width: $ul) {
        gap: 384px;
      }
    }

    .bar {
      display: flex;
      flex-direction: column;
      gap: 32px;

      @media (max-width: $md) {
        &:nth-child(even) {
          .group {
            flex-direction: column-reverse;

            .left {
              margin-bottom: 0;
              margin-top: 64px;
            }
          }
        }
      }

      .group {
        position: relative;
        display: flex;
        gap: 64px;
        align-items: center;

        @media (min-width: $sl) {
          gap: 128px;
        }

        @media (min-width: $ul) {
          gap: 256px;
        }

        @media (max-width: $md) {
          flex-direction: column;
          gap: 0;

          .left {
            margin-bottom: 64px;
          }
        }
      }

      .title {
        color: $white;
        font-size: 44px;

        z-index: 100;

        @media (min-width: $sl) {
          font-size: 64px;
        }

        @media (min-width: $ul) {
          font-size: 96px;
        }

        @media (max-width: $xl) {
          width: 100%;
        }

        @media (max-width: $lg) {
          font-size: 40px;
        }

        @media (max-width: $md) {
          font-size: 38px;
        }

        @media (max-width: $sm) {
          font-size: 18px;
        }
      }

      .text {
        margin-top: 16px;
        color: $white;
        font-size: 24px;
        line-height: 1.25em;

        z-index: 100;

        @media (min-width: $sl) {
          font-size: 32px;
        }

        @media (min-width: $ul) {
          font-size: 48px;
        }

        @media (max-width: $sm) {
          font-size: 16px;
        }
      }

      img:not(.circular-blur) {
        align-self: center;
        width: 100%;

        z-index: 100;
      }

      .left {
        flex-basis: 50%;
      }

      .right {
        flex-basis: 50%;
      }

      .left,
      .right {
        display: flex;
        flex-direction: column;
      }
    }

    .profile-bar {
      .group {
        .right {
          img {
            border-radius: 32px;

            @media (min-width: $sl) {
              border-radius: 48px;
            }

            @media (max-width: $xl) {
              border-radius: 24px;
            }

            @media (max-width: $lg) {
              border-radius: 24px;
            }

            @media (max-width: $md) {
              border-radius: 28px;
            }

            @media (max-width: $sm) {
              border-radius: 16px;
            }
          }
        }
      }
    }

    .info-rows {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 96px;

      .circular-blur {
        position: absolute;

        transform: translate(-75%, -20%);
      }
    }

    .containers {
      .group {
        align-items: stretch;

        @media (max-width: $lg) {
          flex-direction: column;
        }

        .circular-blur {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(45%, -15%);

          @media (max-width: $md) {
            top: 200px;
          }
        }

        .container {
          min-height: 100%;

          background-color: $light-black;
          border-radius: 20px;

          z-index: 100;

          .wrapper {
            display: flex;
            flex-direction: column;
            gap: 16px;

            padding: 72px 48px;

            @media (min-width: $sl) {
              padding: 96px 72px;
              gap: 16px;
            }

            @media (min-width: $ul) {
              padding: 144px 96px;
              gap: 32px;
            }

            @media (max-width: $sm) {
              padding: 32px 24px;
            }

            .title {
              font-size: 32px;
              @include inter(700);

              @media (min-width: $sl) {
                font-size: 40px;
              }

              @media (min-width: $ul) {
                font-size: 64px;
              }

              @media (max-width: $sm) {
                font-size: 18px;
              }

              transition: font-size 300ms ease;
            }

            ul {
              display: flex;
              flex-direction: column;
              gap: 8px;

              li {
                display: list-item;
                list-style: disc;

                margin-left: 24px;

                color: $white;
                font-size: 20px;
                width: unset;
                line-height: 1.5em;

                @media (min-width: $sl) {
                  font-size: 24px;
                }

                @media (min-width: $ul) {
                  font-size: 40px;
                }

                @media (max-width: $sm) {
                  font-size: 12px;
                }

                transition: font-size 300ms ease;
              }
            }
          }
        }
      }
    }

    .supported-games,
    .people-wanted,
    .talent-wanted {
      position: relative;
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 64px;

      @media (max-width: $sm) {
        gap: 48px;
      }

      .title {
        color: $white;
        text-align: center;
        font-size: 48px;

        @media (min-width: $sl) {
          font-size: 64px;
        }

        @media (min-width: $ul) {
          font-size: 96px;
        }

        @media (max-width: $lg) {
          font-size: 44px;
        }

        @media (max-width: $md) {
          font-size: 38px;
        }

        @media (max-width: $sm) {
          font-size: 32px;
        }

        transition: font-size 300ms ease;
      }
    }

    .supported-games {
      .react-multi-carousel-list {
        margin: 0 -242px;

        @media (min-width: $sl) {
          margin: 0 -320px;
        }

        @media (min-width: $ul) {
          margin: 0 -448px;
        }

        @media (max-width: $xl) {
          margin: 0 -128px;
        }

        @media (max-width: $lg) {
          margin: 0 -96px;
        }

        @media (max-width: $md) {
          margin: 0 -64px;
        }

        @media (max-width: $sm) {
          margin: 0 -32px;
        }

        .react-multi-carousel-track {
          .react-multi-carousel-item {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            .item {
              width: calc(100% - 64px);
              margin-bottom: -5px;

              @media (max-width: $xl) {
                width: calc(100% - 48px);
              }

              @media (max-width: $lg) {
                width: calc(100% - 32px);
              }

              @media (max-width: $md) {
                width: calc(100% - 24px);
              }

              @media (max-width: $sm) {
                width: calc(100% - 16px);
              }

              &:hover {
                & > .title-wrapper {
                  opacity: 1;
                }
              }

              .title-wrapper {
                position: absolute;
                inset: 0;

                margin: 0 32px;

                background-color: rgba($light-black, 0.6);
                border-radius: 20px;
                opacity: 0;

                pointer-events: none;
                transition: opacity 300ms ease;

                @media (max-width: $xl) {
                  margin: 0 24px;
                }

                @media (max-width: $lg) {
                  margin: 0 16px;
                  border-radius: 16px;
                }

                @media (max-width: $md) {
                  margin: 0 12px;
                  border-radius: 8px;
                }

                @media (max-width: $sm) {
                  margin: 0 8px;
                  border-radius: 4px;
                }

                .title {
                  position: absolute;
                  inset: 0;

                  height: fit-content;

                  margin: auto 8px;

                  @include inter(700);
                  font-size: 24px;
                }
              }

              .cover {
                width: 100%;
                border-radius: 20px;
                pointer-events: none;

                @media (max-width: $lg) {
                  border-radius: 16px;
                }

                @media (max-width: $md) {
                  border-radius: 8px;
                }

                @media (max-width: $sm) {
                  border-radius: 4px;
                }
              }
            }
          }
        }
      }
    }

    .talent-wanted {
      align-items: center;

      .circular-blur {
        position: absolute;
        left: 0;
        top: 50%;

        transform: translate(-90%, -50%);

        @media (max-width: $sm) {
          transform: translate(-65%, -50%);
        }
      }

      .word-cloud-wrapper {
        position: relative;
        width: 90%;

        z-index: 100;

        @media (max-width: $sm) {
          width: calc(100% + 96px);
        }

        .word-cloud {
          width: 100%;
        }
      }
    }

    .do-next {
      .top {
        .title {
          font-size: 64px;

          @media (min-width: $sl) {
            font-size: 96px;
          }

          @media (min-width: $ul) {
            font-size: 160px;
          }

          @media (max-width: $lg) {
            font-size: 48px;
          }

          @media (max-width: $md) {
            font-size: 40px;
          }

          @media (max-width: $sm) {
            font-size: 32px;
          }

          transition: font-size 300ms ease;
        }
      }

      .group {
        align-items: unset;
        justify-content: stretch;

        gap: 128px;

        @media (max-width: $lg) {
          gap: 64px;
        }

        @media (max-width: $md) {
          gap: 32px;
        }

        @media (max-width: $sm) {
          gap: 0;
        }

        .circular-blur {
          position: absolute;
          right: 0;
          bottom: 0;

          transform: translate(66%, 0%);
        }

        .left {
          gap: 56px;

          .text {
            width: 100%;

            margin: 0;

            font-size: 44px;
            color: $white;

            @media (min-width: $sl) {
              font-size: 56px;
            }

            @media (min-width: $ul) {
              font-size: 80px;
            }

            @media (max-width: $lg) {
              font-size: 38px;
            }

            @media (max-width: $md) {
              font-size: 32px;
            }

            @media (max-width: $sm) {
              font-size: 24px;
            }

            transition: font-size 300ms ease;

            .number {
              color: $gray;
            }

            span:not(.number) {
              display: block;
              margin-left: 1.6em;
            }
          }
        }

        .right {
          align-items: center;
          gap: 48px;

          width: 100%;

          z-index: 100;

          @media (min-width: $sl) {
            gap: 128px;
          }

          @media (min-width: $ul) {
            gap: 192px;
          }

          .button-wrapper {
            height: 150px;
            width: 90%;

            @media (min-width: $sl) {
              height: 175px;
            }

            @media (min-width: $ul) {
              height: 250px;
            }

            @media (max-width: $xl) {
              height: 100px;
            }

            @media (max-width: $md) {
              height: 100px;
              width: 65%;
            }

            @media (max-width: $sm) {
              width: 100%;
              height: 90px;
            }

            .button {
              display: flex;
              align-items: center;

              width: 100%;
              height: 100%;

              border-radius: 20px;

              font-size: 35px;

              @media (min-width: $sl) {
                padding: 0 64px;
              }

              @media (min-width: $ul) {
                padding: 0 96px;
              }

              @media (max-width: $xl) {
                font-size: 24px;
              }

              @media (max-width: $md) {
                font-size: 24px;
              }

              &:hover {
                background-color: $purple;
                color: $white;

                & > .button-inner div {
                  flex-grow: 1;
                }
              }

              &:active {
                background-color: $dark-purple;
              }

              .button-inner {
                flex-grow: 1;
                gap: 16px;

                div {
                  flex-grow: 0;

                  display: flex;
                  justify-items: flex-start;

                  font-size: 32px;
                  transition: flex-grow 300ms ease;
                }
              }
            }
          }

          .discord-widget {
            width: 90%;
            height: 100%;
            min-height: 300px;

            @media (max-width: $md) {
              width: 65%;
            }

            @media (max-width: $sm) {
              width: 90%;
            }
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 128px;

    background-color: $black;
    height: 80px;

    @media (min-width: $sl) {
      height: 128px;
    }

    @media (min-width: $ul) {
      height: 192px;
    }

    @media (max-width: $lg) {
      padding: 0 80px;
    }

    @media (max-width: $md) {
      flex-direction: column;
      padding: 32px 64px;
      height: auto;
      gap: 32px;

      .links {
        width: 100%;
      }
    }

    @media (max-width: $sm) {
      padding: 8px 64px;
      gap: 16px;

      .links {
        flex-direction: column;
        align-items: center;
        gap: 8px;
      }
    }

    transition: padding 300ms ease;

    .links {
      flex-grow: 1;
      display: flex;
      justify-content: space-around;
      align-items: center;

      z-index: 100;
    }

    .banner-wrapper {
      display: flex;
      gap: 16px;

      .ltd {
        color: $gray;

        @media (min-width: $sl) {
          font-size: 18px;
        }

        @media (min-width: $ul) {
          font-size: 28px;
        }
      }

      .banner {
        width: auto;

        @media (min-width: $sl) {
          height: 45px;
        }

        @media (min-width: $ul) {
          height: 65px;
        }
      }

      z-index: 100;
    }

    .icons {
      display: flex;

      z-index: 100;
    }
  }

  .text-gradient {
    display: inline;
    background: linear-gradient(to right, $light-blue, $purple);
    background-size: 200% 200%;
    background-position: 50% 50%;

    @include inter(600);
    background-clip: text;
    -webkit-text-fill-color: transparent;

    &.animate {
      @include pulsate(3000ms);
    }
  }
}

.modal {
  .content-wrapper {
    margin: 32px 0 16px 0;

    @media (max-width: $sm) {
      margin-top: 32px;
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;

    max-width: 400px;
    width: 400px;
    height: 70vh;

    padding: 64px 48px 32px 48px;

    overflow: hidden;

    @media (max-width: $sm) {
      max-height: calc(100vh - 96px);
      height: calc(100vh - 96px);
      max-width: 100vw;
      width: unset;

      padding: 48px 32px 32px 32px;
    }

    .content-wrapper,
    .form-wrapper {
      flex-grow: 1;

      .title-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .title {
          @include inter(700);
          font-size: 22px;

          @media (max-width: $sm) {
            text-align: center;
          }
        }

        .sub-title {
          @include inter(500);
          font-size: 14px;
          color: $steel-gray;

          @media (max-width: $sm) {
            text-align: center;
          }
        }
      }
    }

    .banner {
      max-height: 41px;
      min-height: 41px;
    }

    .buttons {
      display: flex;
      justify-content: center;
    }
  }
}

#register-modal {
  .modal-content {
    .form-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      margin: 32px 0 16px 0;

      overflow: hidden auto;

      @media (max-width: $sm) {
        margin-top: 32px;
      }

      .title-wrapper {
        &.thank-you {
          flex-grow: 1;
          align-items: center;
          justify-content: center;
          text-align: center;

          + .inner {
            flex-grow: 0;
          }
        }
      }

      .form {
        flex-grow: 1;

        overflow: hidden auto;

        .inner {
          overflow-x: hidden;
        }
      }
    }
  }
}

#privacy-modal {
  .modal-content {
    .content-wrapper {
      display: flex;
      flex-direction: column;
      overflow: hidden auto;

      .title {
        @include inter(700);
        font-size: 22px;

        @media (max-width: $sm) {
          text-align: center;
        }
      }

      .body {
        flex-grow: 1;

        padding: 0 24px 32px 0;
        margin-top: 16px;

        overflow: auto;

        h4 {
          margin: 24px 0 12px 0;

          @include inter(700);
          font-size: 18px;
        }

        p {
          margin-top: 6px;
        }

        p,
        li {
          font-size: 16px;
        }

        ul {
          margin: 4px 0 4px 20px;

          li {
            display: list-item;
            list-style: disc;

            ul {
              margin-left: 32px;
              li {
                display: list-item;
                list-style: circle;
              }
            }
          }
        }
      }
    }
  }
}
