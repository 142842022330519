.checkbox-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;

  .checkmark {
    display: none;
  }

  .checkbox-label {
    color: $gray;
    font-size: 16px;

    @media (max-width: $sm) {
      font-size: 12px;
    }
  }

  .box-wrapper {
    position: relative;
    width: 30px;
    height: 30px;
  }
}

input {
  &.checkbox {
    flex-shrink: 0;
    width: 30px !important;
    height: 30px;

    margin: 0;

    border: 0;
    border-radius: 5px;

    box-sizing: border-box;
    border: 1px solid transparent;
    background-color: $dark-white;

    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-property: background-color, border-color;

    appearance: none;
    cursor: pointer;

    &:focus {
      background-color: rgba($dark-white, 0.25);
      border-width: 2px;
      outline: none;
    }

    &.disabled {
      @include disabled;
    }

    &.contained {
      &:hover {
        border-color: $black;
      }

      @each $name, $pairs in $color-pairs {
        &.#{$name} {
          &:focus {
            border-color: map-get($pairs, "light");
          }
          &:checked {
            background-color: map-get($pairs, "light");
          }
        }
      }

      &.success {
        border-color: $green;
      }
    }

    &.outlined {
      border-color: $light-gray;
      background-color: $white;
      &:hover {
        border-color: $black;
      }

      @each $name, $pairs in $color-pairs {
        &.#{$name} {
          &:focus {
            border-color: map-get($pairs, "light");
          }
        }
      }

      &.success {
        border-color: $green;
      }
    }
  }
}

button,
svg {
  &.input-icon {
    position: absolute;
    z-index: 1000;
    padding: 8px;
  }
}

.checkbox:checked + .checkmark {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  color: $dark-white;
  font-size: 22px;
  line-height: 1.45em;
  pointer-events: none;
  margin: 3px;
  height: 24px;
  width: 24px;

  &.outlined {
    color: $light-black;
  }
}

.entry.error {
  input {
    &.checkbox {
      border-color: $red;
    }
  }
}
