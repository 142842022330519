a,
button {
  &.button {
    display: inline-block;

    height: fit-content;

    cursor: pointer;

    border-radius: 5px;
    @include inter(700);
    letter-spacing: 0.3px;
    line-height: 18px;

    box-sizing: border-box;
    border: 1px solid transparent;

    transition: all 300ms ease;

    a {
      color: inherit;
    }

    .button-inner {
      display: flex;
      align-items: center;
    }

    &.extra-small {
      font-size: 12px;
      padding: 2px 6px;
      border-radius: 2px;
    }

    &.small {
      font-size: 12px;
      padding: 8px 8px;
      border-radius: 8px;
    }

    &.medium {
      font-size: 14px;
      padding: 12px 18px;
    }

    &.large {
      font-size: 14px;
      padding: 19px 38.83px;
      border-radius: 12px;
    }

    &.extra-large {
      font-size: 24px;
      padding: 28px 56px;
      border-radius: 12px;
    }
  }

  &.icon {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    border-radius: 50%;

    box-sizing: border-box;
    border: 1px solid transparent;

    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

    &.small {
      padding: 8px;
    }

    &.medium {
      padding: 10px;
    }

    &.large {
      padding: 12px;
    }

    &.extra-large {
      padding: 16px;
    }
  }

  &.button,
  &.icon {
    &.disabled {
      @include disabled;
    }

    &.contained {
      color: $white;
      fill: $white;

      @each $name, $pairs in $color-pairs {
        &.#{$name} {
          background-color: map-get($pairs, "light");
          &:hover {
            background-color: map-get($pairs, "dark");
          }
          &:active {
            background-color: map-get($pairs, "light");
          }
        }
      }

      &.instagram-orange {
        background: $instagram-rainbow;
        &:hover {
          background: $dark-instagram-rainbow;
        }
        &:active {
          background: $instagram-rainbow;
        }
      }

      &.white,
      &.light-gray,
      &.extra-light-gray {
        color: $black;
        fill: $black;
      }
    }

    &.outlined {
      border-width: 1px;
      background-color: transparent;
      &:active {
        background-color: transparent !important;
      }

      @each $name, $pairs in $color-pairs {
        &.#{$name} {
          border-color: map-get($pairs, "light");
          color: map-get($pairs, "light");
          fill: map-get($pairs, "light");
          &:hover {
            background-color: rgba(map-get($pairs, "light"), 0.05);
          }
        }
      }

      &.white,
      &.light-gray,
      &.extra-light-gray {
        &:hover {
          background-color: rgba($dark-white, 0.05);
        }
      }
    }

    &.flat {
      background-color: transparent;
      &:active {
        background-color: transparent !important;
      }

      @each $name, $pairs in $color-pairs {
        &.#{$name} {
          color: map-get($pairs, "light");
          fill: map-get($pairs, "light");
          &:hover {
            background-color: rgba(map-get($pairs, "light"), 0.05);
          }
        }
      }

      &.white,
      &.light-gray,
      &.extra-light-gray {
        &:hover {
          background-color: rgba($dark-white, 0.05);
        }
      }
    }
  }
}
